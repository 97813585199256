import React, {useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./Components/navbar/Navbar";
import BottomNavbar from "./Components/bottomNavbar/bottomNavbar";
import Home from "./Components/homePage/Home";
import BuyAnAkiya from "./Components/buyAnAkiya/BuyAnAkiya";
import GrowYourAkiya from "./Components/growYourAkiya/GrowYourAkiya";
import Community from "./Components/community/Community";
import TopSection from "./Components/Our-services/Topsection/TopSection";
import ScrollToTop from "./ScrollToTop";
import OurProjects from "./Components/ourProjects/OurProjects";
import AboutUs from "./Components/aboutUs/AboutUs";
import AkiyaSearch from "./Components/akiyaSearch/AkiyaSearch";
import Prefecture from "./Components/prefecture/Prefecture";
import Top from "./Components/Internship/TopSection/Top";
import IndividualProperty from "./Components/individualProperty/IndividualProperty";
import IndiviualAkiya from "./Components/individualAkiya/IndividualAkiya";
import BlogWriting from "./Components/BlogWriting/BlogWriting";
import AllBlogs from "./Components/AllBlogs/AllBlogs";
import SingleBlog from "./Components/SingleBlog/SingleBlog";
import Terms from "./Components/Terms/Terms";
import Profile from "./Components/Profile/Profile";
import Protected from "./Components/Protected";
import ProfileSetting from "./Components/profileSettings/ProfileSetting";
import AkiyaConcierge from "./Components/akiyaConcierge/AkiyaConcierge";
import PageNotFound from "./Components/extraPages/PageNotFound";
import Kominka from "./Components/KominkaSmile/Kominka";
import IndiviualKominkaAkiya from "./Components/individualKominkaAkiya/IndividualKominkaAkiya";
import AkiyaOnNews from "./Components/AkiyaOnNews/AkiyaOnNews";
import AkiyaTweak from "./Components/AkiyaTweak/AkiyaTweak";
import AdminProtected from "./AdminProtected";
import Test from "./Components/Misc/Test";
import Acquisition from "./Components/Misc/Acquisition/Acquisition";
import Renovation from "./Components/Misc/Renovation/Renovation";
import SellerAndAssetManagement from "./Components/Misc/SellerAndAssetManagement/SellerAndAssetManagement";
import HowItWorks from "./Components/Our-services/HowItWorks/HowItWorks";
import WhatItCosts from "./Components/Our-services/WhatItCosts/WhatItCosts";
import InvestInAkiya from "./Components/InvestInAkiya/InvestInAkiya";
import DownloadProspectus from "./Components/Our-services/DownloadProspectus/DownloadProspectusFile";
import DownloadProspectusFile from "./Components/Our-services/DownloadProspectus/DownloadProspectusFile";
// Import the custom hook
import PageViewTracker from "./hooks/PageViewTracker";
import ReactGA4 from "react-ga4";


const TRACKING_ID = "G-9X7C3TVRWJ"; // OUR_TRACKING_ID

function App() {
  useEffect(() => {
    // Initialize GA4 with the tracking ID
    ReactGA4.initialize(TRACKING_ID);

    // Log the pageview
    ReactGA4.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);
  return (
    <div className="App overflow-x-hidden">

        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route index element={<Home />} />
          <Route path="our-services" element={<TopSection />} />
          <Route path="our-projects" element={<OurProjects />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="Internship" element={<Top />} />
          <Route path="terms" element={<Terms />} />
          <Route path="/property/:propertyId" element={<IndividualProperty />} />
          <Route path="buy-an-akiya" element={<BuyAnAkiya />} />
          <Route path="grow-your-akiya" element={<GrowYourAkiya />} />
          <Route path="community" element={<Community />} />
          <Route path="blog-writing" element={<Protected><BlogWriting /></Protected>} />
          <Route path="blogs" element={<AllBlogs />} />
          <Route path="blogs/:blogId" element={<SingleBlog />} />
          <Route path="akiya-search" element={<AkiyaSearch />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/prefecture-listing/:prefecture_name" element={<Prefecture />} />
          <Route path="individual-akiya/:akiya_id" element={<IndiviualAkiya />} />
          <Route path="individual_kominka/:akiya_id" element={<IndiviualKominkaAkiya />} />
          <Route path="/profile-page" element={<Protected><Profile /></Protected>} />
          <Route path="/profile-settings" element={<Protected><ProfileSetting /></Protected>} /> 
          <Route path="/akiya-concierge" element={<AkiyaConcierge />} />
          <Route path="/page-not-found" element={<PageNotFound />} />
          <Route path="/kominka" element={<Kominka />} />
          <Route path="/news" element={<AkiyaOnNews />} />
          <Route path="/tweak/:id" element={<AdminProtected password="aezakmi"><AkiyaTweak /></AdminProtected>} />
          <Route path="/miscTesting" element={<AdminProtected password="valdrion"><Test /></AdminProtected>} />
          <Route path="/service/acquisition-service" element={<AdminProtected password="valdrion"><Acquisition /></AdminProtected>} />
          <Route path="/service/renovation-service" element={<AdminProtected password="valdrion"><Renovation /></AdminProtected>} />
          <Route path="/service/asset-service" element={<AdminProtected password="valdrion"><SellerAndAssetManagement /></AdminProtected>} />
          <Route path="/services/how-it-works" element={<HowItWorks />} />
          <Route path="/services/what-it-costs" element={<WhatItCosts />} />
          <Route path="/invest-in-akiya" element={<InvestInAkiya />} />
          <Route path="/download-prospectus" element={<DownloadProspectus />} />
          <Route path="/download-prospectus" element={<DownloadProspectusFile />} />
        </Routes>
        <BottomNavbar />


    </div>

  );
}

export default App;