import React from "react";
import TimeOutImages from "../../sliderImagesContainer/SliderImagesContainer";
import { noto } from "./data";
import shinanmiKaido from "../../images/Home/shinanmiKaido.avif";
import { sliderImages } from "./data";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
function ThirdSOV() {
  const navigate = useNavigate();
  // const firstHeading = "Noto Peninsula";
  // const secondHeading = "A hidden gem of the Sea of Japan";
  // const paragraph =
  //   "The Noto Peninsula (能登半島) in Ishikawa Prefecture extends about 100 kilometers into the Sea of Japan. The peninsula is known for its coastal scenery, particularly along the Okunoto Coast and the Kongo Coast, as well as for its rural atmosphere. Off the beaten path for even experience, it provides a quality seaside experience for the entire family.";
  const firstHeading = "Shimanami Kaido";
  const secondHeading = "A Cyclist's Paradise Over the Seto Inland Sea";
  const paragraph =
    "The Shimanami Kaido (しまなみ海道) is a scenic route connecting Japan's main island of Honshu with the island of Shikoku. Stretching about 70 kilometers, this route is renowned for its breathtaking views and cycling paths that cross multiple islands over a series of impressive bridges. It offers a unique blend of adventure and tranquility, making it a perfect getaway for cyclists and nature enthusiasts alike.";

  const oneLiner = "Learn more about Akiya2.0 Travel Club and join us today!";
  const conciergeHeading =
    "Experience rural freedom without the inconvenience.";
  const conciergeParagraph =
    "Per request, we provide a full range of concierge services from complete amenities to gourmet BBQ sets, or link you up with locals who will show you truly authentic Japanese omotenashi.";

  const newsJT1 = "Standing Together with Noto";
  const newsJT2 = (
    <>
      Akiya2.0 firmly supports local recovery and rebuilding efforts in the Noto
      Peninsula and Ishikawa Prefecture. We have paused our construction plans
      and will resume in 2025. Our sister company, JapanTravel, has partnered
      with the Japan Tourism Board Kanazawa to create a 3-day Ishikawa Tour
      aimed at revitalizing the region's tourism scene and showcasing its beauty
      to the world.
      <br />
      <br />
      For more details on this initiative, please check out the link below.
      <br />
      <br />
      #SupportingNoto #能登と共に
    </>
  );

  return (
    <div className="">
      {/* First in third SOV */}
      <div className="flex flex-col md:flex-row m-5">
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-1/2 flex md:justify-center md:items-center ">
            <img
              className="max-w-[100%] w-[100%] r_md:mb-3"
              src={shinanmiKaido}
              alt=""
            />
          </div>
          <div className="w-full md:w-1/2 px-4 lg:px-[9rem]">
            <h1 className="m-0 text-[24px] font-[800] leading-[36px] mt-3">
              {firstHeading}
            </h1>
            <h1 className="m-0 text-[#5ab963] text-[24px] font-[300] leading-[36px]">
              {secondHeading}
            </h1>
            <p className="text-justify mt-4 text-[#949995] text-[16px] font-[400] leading-[24px]">
              {paragraph}
            </p>
          </div>
        </div>
      </div>

      <div className="mt-[5rem]">
        <h1 className="text-center mt-4 text-[#000] text-[24px] font-[800] leading-[24px]">
          {newsJT1}
        </h1>
        <h1 className="mx-4 md:mx-[4rem] text-center mt-4 text-[#949995] text-[24px] font-[300] leading-[36px]">
          {newsJT2}
        </h1>
        <div className="flex justify-center items-center pb-[2rem]">
          <Link to={"https://en.japantravel.com/feature/ishikawa"}>
            <button
              className=" w-[100vw] md:w-auto md:rounded-md bg-[#5ab963] px-[30px] py-[18px] text-white text-[16px] font-[800]"
              target="_blank"
              onClick={() => navigate("")}
            >
              Support Noto
            </button>
          </Link>
        </div>
      </div>

      {/* Second in third SOV */}
      <div className="mt-[4rem] mb-[3rem]">
        <div className="flex justify-center items-center">
          <h1 className="text-[#5ab963] text-[24px] font-[800] leading-[36px] text-center">
            {oneLiner}
          </h1>
        </div>

        <div className="flex justify-center items-center">
          <Link to="our-projects">
            <button className=" bg-[#5ab963] px-[30px] py-[18px] text-white text-[16px] font-[800] w-[100vw] md:w-auto md:rounded-md">
              Our Projects
            </button>
          </Link>
        </div>

        {/* Roshan will give the component for sliding images */}
        {/* ...... */}
        <div className="f-section mt-[2rem]">
          <TimeOutImages images={sliderImages} />
        </div>
        <div className="mt-[5rem]">
          <h1 className="text-center mt-4 text-[#000] text-[24px] font-[800] leading-[24px]">
            {conciergeHeading}
          </h1>
          <h1 className="mx-4 md:mx-[4rem] text-center mt-4 text-[#949995] text-[24px] font-[300] leading-[36px]">
            {conciergeParagraph}
          </h1>
          <div className="flex justify-center items-center pb-[2rem]">
            <button
              className=" w-[100vw] md:w-auto md:rounded-md bg-[#5ab963] px-[30px] py-[18px] text-white text-[16px] font-[800]"
              onClick={() => navigate("/akiya-concierge")}
            >
              Your Concierge
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThirdSOV;

// Real color for grey:##### #b3b3b3
