import React from "react";
import ReactDOM from "react-dom/client";
import { hydrate, render } from "react-dom"
import "./index.css";
import App from "./App";
import { UserProvider } from "./Context/UserContext";
import { BrowserRouter } from 'react-router-dom';


const rootElement = document.getElementById("root");


const app = (
  <BrowserRouter>
    <UserProvider>

        <App />

    </UserProvider>
  </BrowserRouter>
);

if (rootElement.hasChildNodes()) {
  hydrate(app, rootElement); // For SSR
} else {
  render(app, rootElement); // For CSR
}