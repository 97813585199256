import React, { useEffect, useState } from "react";
import usePrefectureHook from "./usePrefectureHook";
import { useParams } from "react-router-dom";
import AkiyaCard from "../KominkaAkiyaCard/AkiyaCard";
import SortDropDown from "../sortDropDown/SortDropDown";
import MapComponent from "./Maps";
import Loader from "../Loader/Loader";

const Kominka = () => {
  const { akiyaData, sortDropDownOptions, sortingType, handleFavoriteClick } = usePrefectureHook();
  const { prefecture_name } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20); // 5 columns * 4 rows = 20 items per page
  const [viewData, setViewData] = useState([]);
  const [sortBy, setSortBy] = useState("Sort By");
  const [orderBy, setOrderBy] = useState("Order By");

  const handleSortbyChange = (item) => {
    setSortBy(item.display);
  };

  const handleOrderByChange = (item) => {
    setOrderBy(item.display);
  };

  useEffect(() => {
    if (akiyaData && akiyaData.length > 0) {
      let sortedDataCopy = [...akiyaData];

      if (sortBy === "By Price") {
        if (orderBy === "Higher to Lower") {
          sortedDataCopy.sort((a, b) => extractPriceValue(b.price) - extractPriceValue(a.price));
        } else if (orderBy === "Lower to Higher") {
          sortedDataCopy.sort((a, b) => extractPriceValue(a.price) - extractPriceValue(b.price));
        }
      } else if (sortBy === "By Area") {
        if (orderBy === "Higher to Lower") {
          sortedDataCopy.sort((a, b) => extractArea(b.land_area) - extractArea(a.land_area));
        } else if (orderBy === "Lower to Higher") {
          sortedDataCopy.sort((a, b) => extractArea(a.land_area) - extractArea(b.land_area));
        }
      } else if (sortBy === "By Floor Area") {
        if (orderBy === "Higher to Lower") {
          sortedDataCopy.sort((a, b) => extractFloorArea(b.total_area) - extractFloorArea(a.total_area));
        } else if (orderBy === "Lower to Higher") {
          sortedDataCopy.sort((a, b) => extractFloorArea(a.total_area) - extractFloorArea(b.total_area));
        }
      } else if (sortBy === "By Year") {
        if (orderBy === "Higher to Lower") {
          sortedDataCopy.sort((a, b) => extractYear(b.built) - extractYear(a.built));
        } else if (orderBy === "Lower to Higher") {
          sortedDataCopy.sort((a, b) => extractYear(a.built) - extractYear(b.built));
        }
      }

      setViewData(sortedDataCopy);
      setCurrentPage(1); // Reset current page to the first page after sorting
    }
  }, [akiyaData, sortBy, orderBy]);

  const extractPriceValue = (priceString) => {
    if (typeof priceString !== "string") {
      return 0;
    }
    const numericValue = parseFloat(priceString.replace(/[^\d.-]/g, ""));
    return isNaN(numericValue) ? 0 : numericValue;
  };

  const extractArea = (area) => {
    if (!area || typeof area !== "string") {
      return 0;
    }
    const numericValue = parseFloat(area.match(/[\d.]+/g)?.join("") || "0");
    return isNaN(numericValue) ? 0 : numericValue;
  };

  const extractFloorArea = (area) => {
    if (!area || typeof area !== "string") {
      return 0;
    }
    const numericValue = parseFloat(area.match(/[\d.]+/g)?.join("") || "0");
    return isNaN(numericValue) ? 0 : numericValue;
  };

  const extractYear = (built) => {
    if (!built || typeof built !== "string") {
      return 0;
    }
    const match = built.match(/\d+/);
    return match ? parseInt(match[0]) : 0;
  };

  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = viewData.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="flex justify-center gap-3 my-2rem">
      <div className="max-w-1440px lg:mx-3rem md:mx-2rem mx-1rem w-[97%] flex flex-col">
        {akiyaData && akiyaData.length > 0 && (
          <div>
            <h1 className="mt-12 text-left text-[#333333]">{`Kominka Listing`}</h1>
            <div className="map mb-4 h-[30rem] bg-slate-500 w-full">
              <MapComponent
                akiyaData={akiyaData}
                prefectureName={prefecture_name}
                apiKey={"AIzaSyDhd_bCluPIx2cSHS7pGd5wVPkIRU7rpxQ&"}
              />
            </div>
            <div className="w-full 400">
              <div className="w-full h-[4rem] flex justify-end items-center">
                <div className="w-full md:w-[50%] flex justify-end gap-3">
                  <div className="flex gap-2">
                    {sortDropDownOptions && (
                      <SortDropDown
                        options={sortDropDownOptions}
                        text={sortBy}
                        handleChange={handleSortbyChange}
                        color={{ primary: "#e09321" }}
                      />
                    )}
                    {sortDropDownOptions && (
                      <SortDropDown
                        options={sortingType}
                        text={orderBy}
                        handleChange={handleOrderByChange}
                        color={{ primary: "#e09321" }}
                      />
                    )}
                  </div>
                  <button
                    className={`custom-button bg-[#e09321] hover:bg-[#8f6628] lg:px-7 md:px-6 sm:px-6 inline-block rounded-md text-white font-semibold mr-2`}
                  >
                    <button className="flex stroke-green-500" onClick={handleFavoriteClick}>
                      {`Your Favorites `}
                    </button>
                  </button>
                </div>
              </div>

              <div className="grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 mx-3 sm:m-0 gap-4 mt-5 justify-center">
                {currentItems.map((item) => (
                  <AkiyaCard key={item.post_id} item={item} />
                ))}
              </div>
              <div className="flex justify-evenly mt-10 mb-5">
                <button
                  className={`custom-button bg-[#e09321] inline-block text-white font-semibold py-2 px-3`}
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous Page
                </button>
                <button
                  className={`custom-button bg-[#e09321] inline-block text-white font-semibold py-2 px-3`}
                  onClick={() => paginate(currentPage + 1)}
                  disabled={currentItems.length < itemsPerPage}
                >
                  Next page
                </button>
              </div>
            </div>
          </div>
        )}
        {console.log("akiyaData is here for maps",akiyaData)}
        {!akiyaData && <Loader />}
      </div>
    </div>
  );
};

export default Kominka;
