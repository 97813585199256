import React, { useEffect } from "react";
import "./Home.css";
import MainLogo from "../images/Navbar/MainLogo.jpeg";
import FirstSOV from "./HomePageComponents/FirstSOV";
import SecondSOV from "./HomePageComponents/SecondSOV";
import ThirdSOV from "./HomePageComponents/ThirdSOV";
import QuestionBox from "../QuestionBox/QuestionBox";
import LogoSlider from "./HomePageComponents/Slider";

function Home() {
  return (
    <section className="home-container h-[100%] w-[100vw]">
      <FirstSOV />
      <div className="flex justify-center">
        <div className="max-w-[1440px]">
          <SecondSOV />
          <ThirdSOV />
          <div className="max-w-[1440px] w-[100vw]">
            <h1 className="text-center mt-4 text-[#000] text-[24px] font-[800] leading-[24px]">
              Our partners
            </h1>
            <h1 className="mx-4 md:mx-[4rem] text-center mt-4 text-[#949995] text-[24px] font-[300] leading-[36px]">
              These are some of the Japanese city/prefectural governments
              agencies that trust Akiya2.0
            </h1>
            <LogoSlider />
          </div>
          <QuestionBox />
        </div>
      </div>
    </section>
  );
}

export default Home;
